import React from "react";
import Video from "../../components/media/video";
import { Headline2, Headline3, Paragraph } from "../../components/typography";
import styled from "styled-components";
import { theme } from "../../styles/styles";
import FactsBox from "../../components/facts-box";
import FeaturesList from "../../components/features-list";
import QuoteElement from "../../components/quote-element/quote-element";
import { PageElement } from "../../graphql/page-common";
import {
  getElementText,
  getElementVideo,
  getElementImage,
  getElementFact,
} from "../../utilities/pimcore-data-transformer";
import { PimcoreImage } from "../../components/pimcore-image";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";
import { ArticlesIcon, BlogIcon, ConferenceIcon, UniversitiesIcon } from "../../images/icons/Icons";

interface CulutureProps {
  content: Array<PageElement>;
}

interface CellProps {
  $inversed?: boolean;
  $bigger?: "6";
}

interface ParagraphProps {
  padding?: string;
}
interface MarginProps {
  margin?: string;
}

interface AnimationProps {
  $hidden?: boolean;
}

interface GridProps {
  $last?: boolean;
}

interface ImageProps {
  $align?: "left" | "right";
}

const Culture: React.FC<CulutureProps> = ({ content }) => {
  const firstWrapper = React.useRef<HTMLDivElement>(null);
  const secondWrapper = React.useRef<HTMLDivElement>(null);
  const thirdWrapper = React.useRef<HTMLDivElement>(null);
  const fourthWrapper = React.useRef<HTMLDivElement>(null);
  const tl1 = React.useRef<GSAPTimeline>();
  const tl2 = React.useRef<GSAPTimeline>();
  const tl3 = React.useRef<GSAPTimeline>();
  const tl4 = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useLayoutEffect(() => {
    const firstIndexChildrenDesktop = [1, 0, 2];
    const firstIndexChildrenMobile = [0, 1, 2];

    const firstIndex = matchMobile ? firstIndexChildrenMobile : firstIndexChildrenDesktop;

    tl1.current = onScrollAnimationDesktop(
      firstWrapper.current,
      returnRefArray(firstWrapper.current, firstIndex, [1, 2]),
      matchMobile
    );
    tl2.current = onScrollAnimationDesktop(
      secondWrapper.current,
      returnRefArray(secondWrapper.current, firstIndex, [0, 1, 2]),
      matchMobile
    );
    tl3.current = onScrollAnimationDesktop(
      thirdWrapper.current,
      returnRefArray(thirdWrapper.current, firstIndex, [0, 1, 2]),
      matchMobile
    );
    tl4.current = onScrollAnimationDesktop(
      fourthWrapper.current,
      returnRefArray(fourthWrapper.current, firstIndex, [0, 1, 2]),
      matchMobile
    );

    return () => {
      tl1.current?.kill();
      tl2.current?.kill();
      tl3.current?.kill();
      tl4.current?.kill();
    };
  }, []);

  return (
    <>
      <Grid ref={firstWrapper}>
        <HeadlineCell>
          <AnimationWrapper>
            <MarginWrapper margin="tablet:mb-16 pr-16 tablet:pr-0">
              <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[0]) }} />
            </MarginWrapper>
          </AnimationWrapper>
        </HeadlineCell>
        <MediaCell>
          <AnimationWrapper>
            <MarginWrapper margin="mb-4">
              <Video placeholder={getElementImage(content[1])} video={getElementVideo(content[1])} />
            </MarginWrapper>
          </AnimationWrapper>
          <AnimationWrapper>
            <QuoteElement
              message="What surprised me is the diversity of the people here. I’ve never worked in a company with such a range of nationalities and backgrounds."
              name="Geiske"
              position="Program Manager Channel Management"
              modifier="left-4 bottom-4 tablet:left-auto tablet:bottom-52 tablet:right-[22rem]"
            />
            <MarginWrapper>
              <ImageWrapper>
                <PimcoreImage image={getElementImage(content[2])} modifier="w-full rounded-lg" withAspectRatio />
              </ImageWrapper>
            </MarginWrapper>
          </AnimationWrapper>
        </MediaCell>
        <ContentCell>
          <AnimationWrapper>
            <MarginWrapper>
              <ParagraphWrapper>
                <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[3]) }} />
              </ParagraphWrapper>
            </MarginWrapper>
          </AnimationWrapper>
          <AnimationWrapper>
            <FactsBox
              float="left"
              modifier="mr-20 last:mr-0 tablet:mt-10"
              variant="lineheight"
              facts={[getElementFact(content[4]), getElementFact(content[5])]}
            />
          </AnimationWrapper>
        </ContentCell>
      </Grid>

      <Grid ref={secondWrapper}>
        <HeadlineCell $inversed>
          <AnimationWrapper>
            <HeadlineWrapper>
              <MarginWrapper margin="tablet:mb-16">
                <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
              </MarginWrapper>
            </HeadlineWrapper>
          </AnimationWrapper>
        </HeadlineCell>
        <MediaCell $inversed>
          <AnimationWrapper>
            <MarginWrapper margin="mb-8 tablet:mb-4">
              <Video placeholder={getElementImage(content[7])} video={getElementVideo(content[7])} />
            </MarginWrapper>
          </AnimationWrapper>
          <AnimationWrapper $hidden>
            <QuoteElement
              message="Everybody here is very experienced and insightful. So there’s always a feeling that you’re mutually learning from each other."
              name="Anna"
              position="SEO/SEA Manager"
              modifier="hidden tablet:flex tablet:bottom-60 tablet:left-[22rem]"
              left={true}
            />
            <ImageWrapper $align={"left"}>
              <PimcoreImage image={getElementImage(content[8])} modifier="w-full rounded-lg" withAspectRatio />
            </ImageWrapper>
          </AnimationWrapper>
        </MediaCell>
        <ContentCell $inversed>
          <AnimationWrapper>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[9]) }} />
          </AnimationWrapper>
          <AnimationWrapper>
            <FeaturesList
              margin="mt-8 tablet:mt-20"
              features={[
                {
                  icon: ConferenceIcon,
                  label: (
                    <>
                      speaking at <br />
                      conferences
                    </>
                  ),
                },
                {
                  icon: UniversitiesIcon,
                  label: (
                    <>
                      guest speakers
                      <br />
                      at universities
                    </>
                  ),
                },
              ]}
            />
          </AnimationWrapper>
          <AnimationWrapper>
            <FeaturesList
              margin="mt-8 tablet:mt-12"
              features={[
                {
                  icon: ArticlesIcon,
                  label: (
                    <>
                      published
                      <br />
                      articles
                    </>
                  ),
                },
                {
                  icon: BlogIcon,
                  label: (
                    <>
                      blog <br />
                      writing
                    </>
                  ),
                },
              ]}
            />
          </AnimationWrapper>
        </ContentCell>
      </Grid>

      <Grid ref={thirdWrapper}>
        <HeadlineCell>
          <AnimationWrapper>
            <MarginWrapper margin="tablet:mb-16">
              <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[10]) }} />
            </MarginWrapper>
          </AnimationWrapper>
        </HeadlineCell>
        <MediaCell>
          <AnimationWrapper>
            <QuoteElement
              message="If you’re looking for a place where you can share your opinion and change things, then this is the place to be."
              name="Terri"
              position="Head of Digital UX/UI"
              modifier="hidden tablet:flex left-4 bottom-4 tablet:bottom-[26rem] tablet:left-28"
              left={true}
            />
            <MarginWrapper>
              <Video placeholder={getElementImage(content[11])} video={getElementVideo(content[11])} />
            </MarginWrapper>
          </AnimationWrapper>
        </MediaCell>
        <ContentCell>
          <AnimationWrapper>
            <ParagraphWrapper padding="tablet:pr-11">
              <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[12]) }} />
            </ParagraphWrapper>
          </AnimationWrapper>
        </ContentCell>
      </Grid>

      <Grid ref={fourthWrapper} $last>
        <HeadlineCell $inversed>
          <AnimationWrapper>
            <HeadlineWrapper>
              <MarginWrapper margin="tablet:mb-14 tablet:mt-6">
                <Headline2 dangerouslySetInnerHTML={{ __html: getElementText(content[13]) }} />
              </MarginWrapper>
            </HeadlineWrapper>
          </AnimationWrapper>
          <AnimationWrapper>
            <HeadlineWrapper>
              <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[14]) }} />
            </HeadlineWrapper>
          </AnimationWrapper>
        </HeadlineCell>
        <MediaCell $inversed $bigger={"6"}>
          <AnimationWrapper>
            <MarginWrapper>
              <Video placeholder={getElementImage(content[15])} video={getElementVideo(content[15])} />
            </MarginWrapper>
          </AnimationWrapper>
        </MediaCell>
        <ContentCell $inversed>
          <AnimationWrapper>
            <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[16]) }} />
          </AnimationWrapper>
        </ContentCell>
      </Grid>
    </>
  );
};

const Grid = styled.div.attrs<GridProps>(({ $last }) => ({
  className: `grid tablet:grid-cols-12 ${$last ? "mb-24" : "mb-14"}  tablet:mb-36 px-5 scrollspy`,
}))<GridProps>`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: auto 1fr;
  }
`;

const HeadlineWrapper = styled.div.attrs({
  className: "w-full tablet:w-5/6",
})``;

const HeadlineCell = styled.div.attrs<CellProps>(({ $inversed }) => ({
  className: `${$inversed ? "tablet:col-start-2" : "tablet:col-start-8"} tablet:col-span-4 tablet:row-start-1`,
}))<CellProps>``;

const MediaCell = styled.div.attrs<CellProps>(({ $inversed, $bigger }) => ({
  className: `${$inversed ? "tablet:col-start-7" : "tablet:col-start-2"} tablet:col-span-${
    $bigger ? $bigger : "5"
  } tablet:row-start-1 tablet:row-span-2`,
}))<CellProps>``;

const ContentCell = styled.div.attrs<CellProps>(({ $inversed }) => ({
  className: `${
    $inversed ? "tablet:col-start-2" : "tablet:col-start-8"
  } tablet:col-span-4 tablet:row-start-2 tablet:pr-8`,
}))<CellProps>``;

const AnimationWrapper = styled.div.attrs<AnimationProps>(({ $hidden }) => ({
  className: `${$hidden ? "hidden tablet:block " : ""} relative`,
}))<AnimationProps>``;

const ImageWrapper = styled.div.attrs<ImageProps>(({ $align = "right" }) => ({
  className: `block w-4/5 ${
    $align === "left" ? "ml-auto tablet:ml-0 tablet:mr-auto" : "mr-auto tablet:mr-0 tablet:ml-auto"
  }`,
}))<ImageProps>``;

const MarginWrapper = styled.div.attrs<MarginProps>(({ margin }) => ({
  className: `${margin ? margin : "mb-8 tablet:mb-0"} `,
}))<MarginProps>``;

const ParagraphWrapper = styled.div.attrs<ParagraphProps>(({ padding }) => ({
  className: `${padding ? padding : ""}`,
}))<ParagraphProps>``;

export default Culture;
