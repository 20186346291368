import React from "react";
import styled from "styled-components";
import { ImageDataInterface, VideoDataInterface } from "../../graphql/common";
import { IconPlay } from "../icons";
import { PimcoreImage } from "../pimcore-image";
import VideoElementModal from "../video-element-modal/video-element-modal";

interface VideoProps {
  placeholder?: ImageDataInterface;
  video?: VideoDataInterface;
  imageLoading?: "eager" | "lazy" | undefined;
}

const Video: React.FC<VideoProps> = ({ placeholder, video, imageLoading }) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <Wrapper>
        <Placeholder>
          {placeholder && <PimcoreImage image={placeholder} loading={imageLoading} withAspectRatio />}
        </Placeholder>
        <PlayWrapper>
          <PlayButton onClick={() => setShowModal((prev) => !prev)}>
            <PlayIconWrapper>
              <IconPlay />
            </PlayIconWrapper>
          </PlayButton>
        </PlayWrapper>
      </Wrapper>
      {video && showModal && (
        <VideoElementModal
          closeModal={() => setShowModal((prev) => !prev)}
          showModal={showModal}
          srcVideo={process.env.GATSBY_PIMCORE_BASE_URL + video.data.fullpath}
        />
      )}
    </>
  );
};

const Wrapper = styled.div.attrs({ className: "relative" })``;

const Placeholder = styled.div.attrs({
  className: "rounded-lg object-cover w-full overflow-hidden",
})``;

const PlayWrapper = styled.div.attrs({
  className:
    "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-24 h-24 tablet:w-36 tablet:h-36 flex justify-center items-center group rounded-full cursor-pointer",
})``;

const PlayButton = styled.div.attrs({
  className:
    "relative w-24 h-24 tablet:w-36 tablet:h-36 transition-all  duration-300 ease-out border-2 border-white rounded-full backdrop-blur-sm bg-gray/40 group-hover:bg-white group-hover:border-white flex items-center justify-center group-hover:tablet:w-[8.375rem] group-hover:tablet:h-[8.375rem] group-hover:[&_svg_path]:fill-black",
})`


  @supports (-moz-appearance: none) {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const PlayIconWrapper = styled.div.attrs({
  className: "block ml-1 w-5 tabet:w-6",
})`
  svg path {
    transition: fill 300ms ease-out;
  }
`;

export default Video;
