import React from "react";
import styled from "styled-components";
import { IconInterface } from "../../images/icons/Icons";

export interface FeatureData {
  icon: IconInterface;
  label: string | React.ReactNode;
}

interface FeaturesSectionProps {
  features: Array<FeatureData>;
  margin?: string;
}

interface WrapperProps {
  margin?: string;
}

const FeaturesList = React.forwardRef<HTMLDivElement, FeaturesSectionProps>(
  ({ features, margin }, ref) => {
    return (
      <Wrapper ref={ref} margin={margin}>
        {features.map((feature, index) => (
          <Feature key={`feature_${index}`}>
            <FeatureIcon src={feature.icon.src} alt={feature.icon.alt} />
            <FeatureLabel>{feature.label}</FeatureLabel>
          </Feature>
        ))}
      </Wrapper>
    );
  }
);

const Wrapper = styled.div.attrs<WrapperProps>(({ margin }) => ({
  className: `flex ${margin ? margin : "mt-14"} justify-between`,
}))<WrapperProps>``;
const Feature = styled.div.attrs({ className: "tablet:mr-12 w-40" })``;
const FeatureIcon = styled.img.attrs({
  className: "w-10 h-10 tablet:w-14 tablet:h-14 mb-3 tablet:mb-4",
})``;
const FeatureLabel = styled.div.attrs({
  className:
    "font-bold text-white text-benefit tablet:text-lg leading-5 tablet:leading-5",
})``;

export default FeaturesList;
