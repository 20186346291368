import React from "react";
import styled from "styled-components";
import { GraterThanIcon } from "../../images/icons/Icons";

interface BoxProps {
  float?: "left";
  modifier?: string;
}

interface VariantProps {
  variant?: "lineheight";
}

interface FactsBoxProps extends BoxProps, VariantProps {
  facts: Array<FactData>;
}

interface FactData {
  value: string;
  valuePrefix?: string;
  valueSuffix?: string;
  topLabel?: string;
  bottomLabel?: string;
  subBottomLabel?: string;
}

interface UnitProps {
  $align?: "top" | "bottom" | "center";
}

const getValueAlignment = (unit: string): "top" | "bottom" | "center" => {
  if (["th", "nd", "st", "+"].indexOf(unit) >= 0) {
    return "top";
  }

  if (["<", ">"].indexOf(unit) >= 0) {
    return "center";
  }

  return "bottom";
};

const FactsBox: React.FC<FactsBoxProps> = ({ facts, float, modifier, variant }) => {
  return (
    <Box float={float}>
      {facts.map((fact, index) => (
        <Fact key={`fact_box_${index}`} modifier={modifier}>
          {fact.topLabel && <Label dangerouslySetInnerHTML={{ __html: fact.topLabel }} />}
          <ValueWrapper $align={getValueAlignment(fact.valuePrefix || fact.valueSuffix || "")}>
            <Unit unit={fact.valuePrefix} />
            <Value variant={variant}>{fact.value}</Value>
            <Unit unit={fact.valueSuffix} />
          </ValueWrapper>
          {fact.bottomLabel && <Label dangerouslySetInnerHTML={{ __html: fact.bottomLabel }} />}
          {fact.subBottomLabel && <SubLabel dangerouslySetInnerHTML={{ __html: fact.subBottomLabel }} />}
        </Fact>
      ))}
    </Box>
  );
};

const Unit: React.FC<{ unit?: string }> = ({ unit }) => {
  if (!unit) {
    return null;
  }

  if (">" === unit) {
    return <GraterThan src={GraterThanIcon.src} alt={GraterThanIcon.alt} />;
  }

  if ("<" === unit) {
    return <LowerThan src={GraterThanIcon.src} alt="Chevron left icon" />;
  }

  return <UnitWrapper>{unit}</UnitWrapper>;
};

const Box = styled.div.attrs<BoxProps>(({ float }) => ({
  className: `flex ${float === "left" ? "justify-start tablet:justify-between" : "justify-between"}`,
}))<BoxProps>``;

const Fact = styled.div.attrs<BoxProps>(({ modifier }) => ({
  className: `${modifier && modifier}`,
}))<BoxProps>``;

const Label = styled.div.attrs({
  className: "font-bold text-white text-base tablet:text-lg my-2.5",
})``;

const SubLabel = styled.div.attrs({
  className: "font-light text-gray4 text-xs text-base tablet:text-sm mb-2.5",
})``;

const ValueWrapper = styled.div.attrs<UnitProps>(({ $align }) => ({
  className: `flex ${$align === "top" ? "items-start" : $align === "center" ? "items-center" : "items-end"}`,
}))<UnitProps>``;

const Value = styled.span.attrs<VariantProps>(({ variant }) => ({
  className: `font-bold text-white text-6xl ${
    variant === "lineheight" ? "tablet:text-[5.5rem] tablet:leading-[0.8] leading-[0.8]" : "tablet:text-subHeadline"
  }`,
}))<VariantProps>``;

const UnitWrapper = styled.span.attrs({
  className: `font-bold text-white text-3xl tablet:text-4xl`,
})``;

const GraterThan = styled.img.attrs({ className: "w-4 tablet:w-6 self-center mr-1" })``;

const LowerThan = styled.img.attrs({ className: "w-4 tablet:w-6 self-center mr-1 rotate-180" })``;

export default FactsBox;
