import React from "react";
import styled from "styled-components";
import { useMatchMedia } from "../../hooks/use-match-media";
import ClearModal from "../modal/clear-modal";
import EndBodyPortal from "../react-portal/react-portal";

interface VideoProps {
  closeModal: () => void;
  srcVideo: string;
  showModal: boolean;
}

const VideoElementModal: React.FC<VideoProps> = ({
  showModal,
  closeModal,
  srcVideo,
}) => {
  const match = useMatchMedia("(min-width: 768px)");
  const [isDesktop, setDesktop] = React.useState<boolean | null>(null);
  const mobileVideo = React.useRef<HTMLVideoElement | null>(null);

  React.useEffect(() => {
    setDesktop(match);
  }, [match]);

  React.useEffect(() => {
    if (showModal) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [showModal]);

  React.useEffect(() => {
    mobileVideo.current?.addEventListener("webkitendfullscreen", closeModal);

    return () => {
      mobileVideo.current?.removeEventListener(
        "webkitendfullscreen",
        closeModal
      );
    };
  }, []);
  return (
    <EndBodyPortal visible={showModal}>
      <ClearModal onClose={closeModal}>
        {isDesktop ? (
          <VideoWrapper>
            <Video
              src={srcVideo}
              controls={true}
              autoPlay
              playsInline
              onEnded={closeModal}
            />
          </VideoWrapper>
        ) : (
          <Video
            ref={mobileVideo}
            src={srcVideo}
            autoPlay
            onEnded={closeModal}
            controls={true}
          />
        )}
      </ClearModal>
    </EndBodyPortal>
  );
};

export default VideoElementModal;

const VideoWrapper = styled.div.attrs({
  className: "w-full h-full",
})``;
const Video = styled.video.attrs({
  className: " w-full	rounded-lg",
})`
  max-height: 80vh;
`;
