import React from "react";
import styled from "styled-components";
import { ButtonSolid } from "../../components/button";
import { PimcoreImage } from "../../components/pimcore-image";
import { ImageDataInterface } from "../../graphql/common";
import { TeamMemberInterface } from "../../graphql/team-page";
import { useMatchMedia } from "../../hooks/use-match-media";
import { theme } from "../../styles/styles";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";

type PhotoSizeTypes = "square" | "landscape" | "portrait";

interface TeamGalleryProps {
  title: string;
  members: Array<TeamMemberInterface>;
  first?: number;
  firstMobile?: number;
}

interface Photo {
  image: any;
  size: PhotoSizeTypes;
}

interface GridProps {
  $expanded: boolean;
}

interface PhotoProps {
  $size: PhotoSizeTypes;
}

const getPhotoSize = (photo: ImageDataInterface): PhotoSizeTypes => {
  const { dimensions } = photo;
  switch (true) {
    case dimensions.width === dimensions.height:
      return "square";
    case dimensions.width > dimensions.height:
      return "landscape";
    case dimensions.width < dimensions.height:
      return "portrait";
  }
  return "square";
};

const TeamGallery: React.FC<TeamGalleryProps> = ({ title, members, first = 0, firstMobile = 0 }) => {
  const matchMobile = useMatchMedia("(max-width: 767.9px)");
  const [showFirst, setShowFirst] = React.useState<number>(matchMobile ? firstMobile : first);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const tl = React.useRef<GSAPTimeline>();
  const headlineTl = React.useRef<GSAPTimeline>();

  React.useEffect(() => {
    if (matchMobile) {
      setShowFirst(firstMobile);
    } else {
      setShowFirst(first);
    }

    tl.current = onScrollAnimationDesktop(wrapperRef.current, [wrapperRef.current], matchMobile, ["8rem", "18rem"]);

    if (wrapperRef.current?.children[0]) {
      headlineTl.current = onScrollAnimationDesktop(
        wrapperRef.current?.children[0],
        [wrapperRef.current?.children[0]],
        matchMobile,
        ["2rem", "4rem"]
      );
    }

    return () => {
      tl?.current?.kill();
      headlineTl?.current?.kill();
    };
  }, [matchMobile]);

  const onExpandToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  if (!members?.length || !showFirst) {
    return null;
  }

  return (
    <Grid $expanded={expanded} ref={wrapperRef}>
      <HeadlineCell>
        <Headline dangerouslySetInnerHTML={{ __html: title }} />
      </HeadlineCell>
      {members.slice(0, showFirst).map((member, index) => (
        <Photo key={`team_member_${index}`} $size={getPhotoSize(member.photo)}>
          <ImageWrapper $size={getPhotoSize(member.photo)}>
            <PimcoreImage
              image={member.photo}
              modifier={`rounded-t-lg tablet:rounded-lg w-full ${
                ["landscape", "portrait"].indexOf(getPhotoSize(member.photo)) > -1
                  ? "object-[center_-1rem] tablet:object-[50%_50%]"
                  : "object-[50%_50%]"
              }`}
              withAspectRatio
            />
          </ImageWrapper>
          <MemberInfo>
            <MemberTitle>
              <strong>{member.name}</strong>
            </MemberTitle>
            <MemberTitle>{member.position}</MemberTitle>
          </MemberInfo>
        </Photo>
      ))}
      {expanded &&
        members.slice(showFirst).map((member, index) => (
          <Photo key={`team_member_${index}`} $size={getPhotoSize(member.photo)}>
            <ImageWrapper $size={getPhotoSize(member.photo)}>
              <PimcoreImage
                image={member.photo}
                modifier={`rounded-t-lg tablet:rounded-lg w-full ${
                  ["landscape", "portrait"].indexOf(getPhotoSize(member.photo)) > -1
                    ? "object-[center_-1rem] tablet:object-[50%_50%]"
                    : "object-[50%_50%]"
                }`}
                withAspectRatio
              />
            </ImageWrapper>
            <MemberInfo>
              <MemberTitle>
                <strong>{member.name}</strong>
              </MemberTitle>
              <MemberTitle>{member.position}</MemberTitle>
            </MemberInfo>
          </Photo>
        ))}
      {!expanded && (
        <ExpandWrapper>
          <ButtonSolid href={"#"} onClick={onExpandToggle}>
            Show all ({members.length})
          </ButtonSolid>
        </ExpandWrapper>
      )}
    </Grid>
  );
};

const Grid = styled.div.attrs<GridProps>(({ $expanded }) => ({
  className: `grid grid-cols-2 -mt-20 tablet:mt-0 tablet:grid-cols-12 gap-4 grid-flow-row-dense px-4 relative bg-gray2 pt-10 tablet:px-11 tablet:pt-40${
    $expanded ? " pb-10 tablet:pb-40" : ""
  } scrollspy`,
}))<GridProps>``;

const HeadlineCell = styled.div.attrs({
  className: "col-span-2 tablet:col-span-4 tablet:col-start-2 ",
})``;

const Headline = styled.h2.attrs({
  className: "text-white font-light text-4xl tablet:text-7xl mb-5 tablet:mb-0 w-2/3",
})``;

const MemberInfo = styled.div.attrs({
  className:
    "p-4 bg-gray7 w-full h-full tablet:h-auto tablet:bg-gray7/50 tablet:rounded-lg tablet:backdrop-blur-lg tablet:absolute tablet:bottom-2 tablet:left-2 tablet:w-40 tablet:transition-transform tablet:ease-out tablet:duration-300",
})`
  @media ${theme.mediaQueries.tablet} {
    transform: translateY(120%);
  }
`;
const Photo = styled.div.attrs<PhotoProps>(({ $size }) => ({
  className: `${
    $size === "portrait"
      ? "col-span-2 tablet:row-span-2 tablet:col-span-3"
      : $size === "landscape"
      ? "col-span-2 tablet:col-span-3"
      : "col-span-1 tablet:col-span-2"
  } relative overflow-hidden rounded-lg`,
}))<PhotoProps>`
  :hover ${MemberInfo} {
    transform: translateY(0);
  }
`;

const ImageWrapper = styled.div.attrs<PhotoProps>(({ $size }) => ({
  className: `${["landscape", "portrait"].indexOf($size) > -1 ? "h-[10rem] overflow-hidden tablet:h-auto" : ""}`,
}))<PhotoProps>``;

const MemberTitle = styled.p.attrs({ className: "text-sm leading-none" })``;

const ExpandWrapper = styled.div.attrs({
  className:
    "absolute bottom-0 left-0 right-0 flex justify-center items-center h-72 bg-gradient-to-b from-transparent via-gray2 to-gray2",
})``;

export default TeamGallery;
