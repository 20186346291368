import React from "react";
import styled from "styled-components";
import { Hero, HeroHeadline } from "../components/hero";
import MainSection from "../components/main-section/main-section";
import SectionGallery from "../components/section-gallery";
import Culture from "../sections/team/culture";
import TeamGallery from "../sections/team/team-gallery";
import { JobsSearchTile, TileNavigation } from "../components/tile-navigation";
import Layout from "../components/layout";
import { graphql, PageProps } from "gatsby";
import { JobOffersCountInterface } from "../graphql/jobs";
import { TeamPageContentInterface } from "../graphql/team-page";
import { transformHeroData } from "../utilities/pimcore-data-transformer";
import Scrollspy from "../components/scrollspy/scrollspy";

interface TeamPageDataInterface {
  pimcore: {
    jobOffersCount: JobOffersCountInterface;
    content: TeamPageContentInterface;
  };
}

const labels = [
  "Getting X Done together",
  "A diverse team",
  "Digital experts",
  "Your voice counts",
  "Immer besser",
  "Meet the team",
];

const TeamPage: React.FC<PageProps<TeamPageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="team"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{
        title: content.metaOgTitle,
        description: content.metaOgTitle,
        image: content.metaOgImage,
      }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <Hero {...transformHeroData(content)}>
          <HeroHeadline dangerouslySetInnerHTML={{ __html: content.heroTitle || "" }} />
        </Hero>
      </Header>
      <Main>
        <MainSection
          headline={content.mainSectionHeadline}
          subHeadline={content.mainSectionSubheadline}
          content={content.mainSectionContent}
          background={content.mainSectionBackground}
          backgroundMobile={content?.mainSectionBackgroundMobile}
        >
          <SectionGallery images={content.gallery} />
        </MainSection>
        <Culture content={content.culture} />
        <TeamGallery
          title={content.teamMembersSectionTitle}
          members={content.teamMembers}
          first={content.teamMembersDisplayed}
          firstMobile={content.teamMembersDisplayedMobile}
        />
        <TileNavigation top={"work"} bottom={"office"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
        <Scrollspy labels={labels} gap={6} />
      </Main>
    </Layout>
  );
};

const Main = styled.main.attrs({ className: "x-background overflow-x-hidden -mt-24" })``;

const Header = styled.header.attrs({ className: "h-screen" })``;

export const query = graphql`
  query {
    pimcore {
      ...teamPageData
      ...jobOffersCount
    }
  }
`;

export default TeamPage;
